/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */
@import "bootstrap-icons/font/bootstrap-icons.css";

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body[data-theme="dark"] {
    --color-bg-primary: 30 39 46;
    --color-bg-emphasis: 40 52 61;

    --color-font: 210 218 226;
    --color-font-faded: 175 177 182;

    --color-warning: 249 202 36;

    --color-link: 75 207 250;
    --color-link-hover: 87 95 207;

    --color-code-keyword: 75 207 250;
    --color-code-logic: 182 125 178;
    --color-code-punct: 169 173 177;
    --color-code-var: 246 157 80;
  }

  body[data-theme="light"] {
    --color-link: 41 128 185;
    --color-link-hover: 192 57 43;

    --color-bg-primary: 255 255 255;
    --color-bg-emphasis: 221 221 221;

    --color-font: 0 0 0;
    --color-font-faded: 85 87 94;

    --color-warning: 255 193 7;

    --color-code-keyword: 39 124 180;
    --color-code-logic: 142 68 173;
    --color-code-punct: 82 84 91;
    --color-code-var: 194 78 0;
  }

  h1 {
    @apply text-3xl pb-6;
  }

  h2 {
    @apply text-2xl pb-4;
  }

  h3 {
    @apply text-xl pb-2;
  }

  ul {
    @apply list-disc pl-8 mb-4;
  }

  ol {
    @apply list-decimal pl-8 mb-4;
  }

  li > ul,
  li > ol {
    @apply mb-0;
  }
}

:root {
  /* Github comment */
  --negative-color: #e74c3c;
  --negative-color-half: #e74c3c80;
  --positive-color: #2ecc71;
  --positive-color-half: #2ecc7180;

  --negative-color__colorblind: #ffc20a;
  --negative-color-half__colorblind: #ffc20a80;
  --positive-color__colorblind: #0c7bdc;
  --positive-color-half__colorblind: #0c7bdc80;
  /* End github comment */
}

/* START Custom styling for Table of Contents generated by Kramdown */

ul#markdown-toc {
  @apply pl-0 list-none;
}

ul#markdown-toc ul {
  @apply list-none;
}

/* END Custom styling for Table of Contents generated by Kramdown */

/* START Custom styling for footnotes generated by kramdown */

.footnotes p {
  @apply mb-0 text-sm;
}

/* END Custom styling for footnotes generated by kramdown */

.positive {
  background-color: var(--positive-color-half);
  border: 1px solid var(--positive-color);
}

.negative {
  background-color: var(--negative-color-half);
  border: 1px solid var(--negative-color);
}

.positive.colorblind {
  background-color: var(--positive-color-half__colorblind);
  border: 1px solid var(--positive-color__colorblind);
}

.negative.colorblind {
  background-color: var(--negative-color-half__colorblind);
  border: 1px solid var(--negative-color__colorblind);
}

small {
  @apply text-font-faded;
  font-size: 0.875rem;
}

/* Blog post index */

.post a:hover .title {
  text-decoration: underline;
}

/* START Global layout styles */

.content {
  min-height: calc(100vh - 231px);
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

.navbar {
  max-width: 900px;
}

body {
  @apply text-font-primary bg-primary;
}

a {
  @apply text-link-color;
  cursor: pointer;
  text-decoration: underline;
}

a:hover {
  @apply text-link-hover;
}

code {
  color: #991e5c;
}

/* END Global layout styles */
